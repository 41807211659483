import React from 'react';
import MailChimpForm from '../components/mailchimp-form'

const Newsletter = ({ children }) => {
  return (
  <div className="subsec-container">

      <MailChimpForm/>

  </div>
)}

export default Newsletter