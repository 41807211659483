import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import { Map, Users, Globe, DollarSign, ArrowRight } from "react-feather"
// import { useStaticQuery, graphql } from "gatsby"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Dropdown, Button, ButtonGroup, SplitButton } from "react-bootstrap"

const Footer = ({ siteTitle }) => (
  <footer className="footer">
    <Container className="px-0">
      <Row>
        {/* 
          <Col lg="2" md="2" className="footer-col">

          </Col> */}
        <Col lg="2" md="2" className="complex-footer-col">
          <AniLink fade to="/community" duration={0.4}>
            <div className="footer-feature">
              <div className="footer-feature-container">
                <h3 className="footer-feature-title">
                  Join the Community
                  <Users className="slack-icon" />
                </h3>
                <p className="footer-feature-description">
                  Enter the forum of founders from all over the world building
                  their startups to share, ask and learn.
                </p>
              </div>
            </div>
          </AniLink>

          <div className="normal-footer">
            <AniLink fade to="/startup-visas" className="titular-link">
              <h4>Startup Visas</h4>
              <ArrowRight className="titular-arrow" />
            </AniLink>

            <ul className="visas-list-unstyled">
              <Col>
                <li>
                  <AniLink fade to="/startup-visas/canada" duration={0.4}>
                    🇨🇦 Canada
                  </AniLink>
                </li>
                <li>
                  <AniLink fade to="/startup-visas/estonia" duration={0.4}>
                    🇪🇪 Estonia
                  </AniLink>
                </li>

                <li>
                  <AniLink fade to="/startup-visas/lithuania" duration={0.4}>
                    🇱🇹 Lithuania
                  </AniLink>
                </li>

                <li>
                  <AniLink fade to="/startup-visas/netherlands" duration={0.4}>
                    🇳🇱 Netherlands
                  </AniLink>
                </li>
              </Col>
              <Col>
                <li className="right-lis">
                  <AniLink fade to="/startup-visas/latvia" duration={0.4}>
                    🇱🇻 Latvia
                  </AniLink>
                </li>
                <li className="right-lis">
                  <AniLink fade to="/startup-visas/finland" duration={0.4}>
                    🇫🇮 Finland
                  </AniLink>
                </li>
                <li className="right-lis">
                  <AniLink fade to="/startup-visas/denmark" duration={0.4}>
                    🇩🇰 Denmark
                  </AniLink>
                </li>
              </Col>
            </ul>
          </div>
        </Col>

        <Col lg="2" md="2" className="footer-col">
          <AniLink fade to="/ecosystems" className="titular-link">
            <h4>Ecosystems* </h4>
            <ArrowRight className="titular-arrow" />
          </AniLink>

          <ul className="list-unstyled">
            <li>
              <AniLink fade to="/ecosystems/estonia" duration={0.4}>
                🇪🇪 Estonia
              </AniLink>
            </li>
            <li>
              <AniLink fade to="/ecosystems/lithuania" duration={0.4}>
                🇱🇹 Lithuania
              </AniLink>
            </li>
          </ul>
        </Col>

        <Col lg="2" md="2" className="footer-col">
          <h4 className="static-title">More</h4>

          <ul className="list-unstyled">
            {/* <li>
              <AniLink fade to="/founders" className="extra-link" duration={0.4}>
                  For Founders
                </AniLink>
              </li> */}

            {/* <li>
              <AniLink fade to="/enable-founders" className="extra-link" duration={0.4}>
                  Hire Immigrants
                </AniLink>
              </li> */}

            <li>
              <AniLink
                fade
                to="/unicorns"
                className="extra-link"
                duration={0.4}
              >
                Unicorns
              </AniLink>
            </li>

            <li>
              <AniLink
                fade
                to="/investors"
                className="extra-link"
                duration={0.4}
              >
                Investors
              </AniLink>
            </li>

            <li>
              <AniLink
                fade
                to="/interviews"
                className="extra-link"
                duration={0.4}
              >
                Interviews
              </AniLink>
            </li>

            {/* 
              <li>
                <Link className="extra-link" to="https://t.me/joinchat/Jhr2gxL3mambwfZlapQY3A">
                  Telegram
                </Link>
              </li> */}
          </ul>
        </Col>

        {/* <Col lg="2" md="2" className="footer-col">
            <h4 className="static-title">Us</h4>
  
            <ul className="list-unstyled">

            </ul>
          </Col> */}
      </Row>
    </Container>

    <Row className="trailing">
      <Col lg="8" md="8" id="relevant-trail">
        <ul className="list-unstyled">
          <li id="year">
            © {new Date().getFullYear()} Immigrant Founders
            {` `}
          </li>

          <div id="non-year">
            <li>
              <AniLink fade to="/why" className="extra-link" duration={0.4}>
                Why
              </AniLink>
            </li>
            {/* <li>
              <Link className="extra-link" to="/work">
                Work with us
                </Link>
            </li> */}

            {/* <li>
              <Link to="/docs">
                Term
                </Link>
            </li>
            <li>
              <Link to="/docs">
                Privacy
                </Link>
            </li> */}
          </div>
        </ul>
      </Col>
    </Row>
  </footer>
)

export default Footer
