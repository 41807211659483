// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Map, Users, Globe, DollarSign, Book, Radio, Tv } from "react-feather"
// import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Dropdown, Button, ButtonGroup, SplitButton } from "react-bootstrap"

const Header = ({ siteTitle }) => {
  return (
    <header>
      <ul className="nav">
        <li>
          <AniLink
            fade
            to="/"
            className="logo-text"
            activeClassName="active-link"
            duration={0.4}
          >
            IF
          </AniLink>
        </li>

        <div className="right-header">
          <li>
            <Dropdown as={ButtonGroup} className="visa-btn-group">
              <Button variant="visas">
                <AniLink
                  fade
                  to="/startup-visas"
                  className="top-nav-button"
                  activeClassName="active-link"
                  duration={0.4}
                >
                  <div className="header-icon-container">
                    <Map className="header-icon" />
                  </div>
                  <div className="link-text visas-link">Startup Visas</div>
                </AniLink>
              </Button>

              <Dropdown.Toggle
                split
                variant="visas"
                id="dropdown-split-basic"
              />
              <Dropdown.Menu className="country-dropdown" drop="down">
                <Dropdown.Item
                  as={AniLink}
                  fade
                  to="/startup-visas/canada"
                  duration={0.4}
                >
                  🇨🇦 Canada
                </Dropdown.Item>
                <Dropdown.Item
                  as={AniLink}
                  fade
                  to="/startup-visas/estonia"
                  duration={0.4}
                >
                  🇪🇪 Estonia
                </Dropdown.Item>
                <Dropdown.Item
                  as={AniLink}
                  fade
                  to="/startup-visas/latvia"
                  duration={0.4}
                >
                  🇱🇻 Latvia
                </Dropdown.Item>
                <Dropdown.Item
                  as={AniLink}
                  fade
                  to="/startup-visas/lithuania"
                  duration={0.4}
                >
                  🇱🇹 Lithuania
                </Dropdown.Item>
                <Dropdown.Item
                  as={AniLink}
                  fade
                  to="/startup-visas/finland"
                  duration={0.4}
                >
                  🇫🇮 Finland
                </Dropdown.Item>
                <Dropdown.Item
                  as={AniLink}
                  fade
                  to="/startup-visas/denmark"
                  duration={0.4}
                >
                  🇩🇰 Denmark
                </Dropdown.Item>
                <Dropdown.Item
                  as={AniLink}
                  fade
                  to="/startup-visas/netherlands"
                  duration={0.4}
                >
                  🇳🇱 Netherlands
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>

          <li>
            <Dropdown as={ButtonGroup} className="ecosystem-btn-group">
              <Button variant="visas">
                <AniLink
                  fade
                  to="/ecosystems"
                  className="top-nav-button"
                  activeClassName="active-link"
                  duration={0.4}
                >
                  <div className="header-icon-container">
                    <Globe className="header-icon" />
                  </div>
                  <div className="link-text visas-link">Ecosystems</div>
                </AniLink>
              </Button>

              <Dropdown.Toggle
                split
                variant="visas"
                id="dropdown-split-basic"
              />
              <Dropdown.Menu className="ecosystem-dropdown" drop="down">
                <Dropdown.Item
                  as={AniLink}
                  fade
                  to="/ecosystems/estonia"
                  duration={0.4}
                >
                  🇪🇪 Estonia
                </Dropdown.Item>
                <Dropdown.Item
                  as={AniLink}
                  fade
                  to="/ecosystems/lithuania"
                  duration={0.4}
                >
                  🇱🇹 Lithuania
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>

          {/* <li>
        <AniLink fade to="/guides" className="top-nav-link guides" activeClassName="active-link" duration={1}>
        <div className="header-icon-container">
          <Book className="header-icon" />
        </div>
        <div className="link-text">Startup Guides</div>
        </AniLink>
      </li> */}
          {/* 
      <li className="header-link">
        <AniLink fade to="/community" className="top-nav-link" activeClassName="active-link" duration={0.4}>
        <div className="header-icon-container">
          <Users className="header-icon" />
        </div>
        <div className="link-text">Community</div>
        </AniLink>
      </li> */}

          {/* <li className="header-link">
        <AniLink fade to="/remote-investors" className="top-nav-link" activeClassName="active-link" duration={0.4}>
        <div className="header-icon-container">
          <DollarSign className="header-icon" />
        </div>
        <div className="link-text">Remote Investors</div>
        </AniLink>
      </li> */}

          {/* <li className="header-link">
        <AniLink fade to="/interviews" className="top-nav-link" activeClassName="active-link" duration={0.4}>
        <div className="header-icon-container">
          <Coffee className="header-icon" />
        </div>
        <div className="link-text">Interviews</div>
        </AniLink>
      </li> */}

          {/* <li id="newsletter">
      <AniLink fade to="/archive" className="" activeClassName="active-link" duration={1}>
        <div className="header-icon-container">
          <Archive className="header-icon" />
        </div>
        <div className="link-text">Newsletter Archive</div>
      </AniLink>
      </li> */}

          <li id="live">
            <AniLink
              fade
              to="/live"
              className="top-nav-link"
              activeClassName="active-link"
              duration={1}
            >
              <div className="header-icon-container">
                <Radio className="header-icon" />
              </div>
              <div className="link-text">Live</div>
            </AniLink>
          </li>
        </div>
      </ul>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
