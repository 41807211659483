import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {Mail, CheckCircle, Check, AlertCircle} from 'react-feather'

// Add classes as element is moved to view port
function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  const className = props.className ? props.className : '';
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);
  return (
    <div
      className={`fade-in ${isVisible ? 'is-visible' : ''} ${className}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

export default class MailChimpForm extends React.Component {

    state = {
        email: "",
        isSuccess: false,
        isError: false,
        errorMsg: ""
    }

  _handleSubmit = e => {
    e.preventDefault();

    this.setState({
      isError: false,
      isSuccess: false
    });
      
    addToMailchimp(this.state.email) // listFields are optional if you are only capturing the email address.
    .then(data => {

      if("error" == data.result) {
            this.setState({
                isError: true,
                errorMsg: data.msg.split('<')[0]
            })
        } else {
            this.setState({ isSuccess: true })
        }
      //alert('Thanks! You have successfully subscribed.');
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      console.log(data)
    })
    .catch(() => {
        this.setState({
            isError: true,
            errorMsg: "Define your Error here"
        })
      // unnecessary because Mailchimp only ever
      // returns a 200 status code
      // see below for how to handle errors
    })
  }

  _handleInput = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render () {
    return (
        <div className="form-container">
            <div className="form-left">
              <h3 className="subsec-title">
                {/* <div className="header-icon-container">
                  <Mail className="subsec-icon" />
                </div> */}
                Subscribe to our newsletter for updates on startups, ecosystem policy, and opportunities for immigrant founders
              </h3>
{/* 
              <ul className="expectations-list">
                <li className="expectation">
                <div className="header-icon-container">
                  <CheckCircle className="list-icon" />
                </div>
                Monumental policy changes favorable to startups
                </li>
                <li className="expectation">
                <div className="header-icon-container">
                  <CheckCircle className="list-icon" />
                </div>Significant startup ecosystem related developments
                </li>
                <li className="expectation">
                <div className="header-icon-container">
                  <CheckCircle className="list-icon" />
                </div>Interviews with founders and program managers
                </li>
            </ul> */}

            </div>
            <div className="form-right">
                <div className="left-container">

            {
              this.state.isSuccess &&
                <FadeInSection>
                    <div className="success">
                        <div className="check-icon-container">
                            <Check className="check-icon"/> 
                        </div>
                        <div className="success-text">
                            Thanks! You have successfully subscribed!
                        </div>
                    </div>
                </FadeInSection>
            }
            {
              this.state.isError &&
                <FadeInSection>
                    <div className="failure">
                        <div className="failure-icon-container">
                            <AlertCircle className="failure-icon"/> 
                        </div>
                        <div className="failure-text">
                            { this.state.errorMsg }
                        </div>
                    </div>
                </FadeInSection>
            }
            {
                !this.state.isSuccess &&
                <FadeInSection>
                    <form onSubmit={ this._handleSubmit }>
                    <input className={ "ba b--dark-gray bg-near-white pa2 sans-serif w-100 mb2" + (this.state.isError ? ' has-error' : '') } type="email" name="email" value={ this.state.email } onChange={ this._handleInput } placeholder="type your email address"/>
                    <input type="submit" className="submit-btn" value="Subscribe ->"/>
                    <p className="small-description">Low frequency, unsubscribe at will.</p>
                    </form>
                </FadeInSection>
            }

            </div>
        </div>

        </div>
    )
  }
}