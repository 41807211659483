
import React from "react"
import World from "./world-map/world-map.js"
import { SVGMap } from "react-svg-map"
import { getLocationName, getLocationId } from '../components/utils';
import AniLink from "gatsby-plugin-transition-link/AniLink"

class InteractiveWorldMap extends React.Component {

// const WorldMap = ({ children }) => {
	constructor(props) {
		super(props);
    // Linking locations ids with urls
    
	this.links = {
		canada: '/startup-visas/canada',
		estonia: '/startup-visas/estonia',
		latvia: '/startup-visas/latvia',
		lithuania: '/startup-visas/lithuania',
		denmark: '/startup-visas/denmark',
		netherlands: '/startup-visas/netherlands',
		finland: '/startup-visas/finland',
		canada_outline: '/startup-visas/canada',
		// united_kingdom: '/startup-visas/united-kingdom',
		// united_kingdom_outline: '/startup-visas/united-kingdom',
		estonia_outline: '/startup-visas/estonia',
		latvia_outline: '/startup-visas/latvia',
		lithuania_outline: '/startup-visas/lithuania',
		denmark_outline: '/startup-visas/denmark',
		netherlands_outline: '/startup-visas/netherlands',
		finland_outline: '/startup-visas/finland',
};
    
		this.state = {
		currentLink: null,
      pointedLocation: null,
      focusedLocation: null,
			clickedLocation: null,
			tooltipStyle: {
				display: 'none'
			}
		};

		this.handleLocationMouseOver = this.handleLocationMouseOver.bind(this);
		this.handleLocationMouseOut = this.handleLocationMouseOut.bind(this);
		this.handleLocationMouseMove = this.handleLocationMouseMove.bind(this);
		this.handleLocationClick = this.handleLocationClick.bind(this);
		this.handleLocationFocus = this.handleLocationFocus.bind(this);
		this.handleLocationBlur = this.handleLocationBlur.bind(this);
  }
  
	handleLocationMouseOver(event) {
		const pointedLocation = getLocationName(event);
    this.setState({ pointedLocation });
    console.log('hovering over ' + pointedLocation);
	}

	handleLocationMouseOut() {
    // this.turnOffRedTimeout = setTimeout(() => { 
    //   this.setState(() => ({pointedLocation: null, tooltipStyle: { display: 'none' }}))
    // }, 100);

    // componentWillUnmount() {
    //   // we set the timeout to this.turnOffRedTimeout so that we can
    //   // clean it up when the component is unmounted.
    //   // otherwise you could get your app trying to modify the state on an
    //   // unmounted component, which will throw an error
    //   clearTimeout(this.turnOffRedTimeout);
    this.setState({ pointedLocation: null, tooltipStyle: { display: 'none' } });
	}

	handleLocationMouseMove(event) {
		const tooltipStyle = {
			display: 'block',
			top: event.clientY - 50,
			left: event.clientX + 10
		};
		this.setState({ tooltipStyle });
  }

  handleLocationClick(event) {
		const clickedLocation = getLocationName(event);
		const clickedLocationId = getLocationId(event);
		this.setState({ clickedLocation: clickedLocation });
    //window.open(this.links[clickedLocationId], '_blank');

    // Set state
    this.setState({ currentLink: this.links[clickedLocationId] });

    // Click on link now
    setTimeout(() => {
	  let linkRef = document.getElementById("linknav");
	  linkRef.click();
	}, 50)
    console.log('clicked '+ clickedLocation)
	}

	handleLocationFocus(event) {
		const focusedLocation = getLocationName(event);
		this.setState({ focusedLocation: focusedLocation });
	}

	handleLocationBlur() {
		this.setState({ focusedLocation: null });
	}

	getLocationClassName(location, index) {
		// Generate random heat map
		return `svg-map__location svg-map__location--heat${index % 4}`;
	}
    render () {
      
      return (
        <div>
          <SVGMap
            map={World}
            locationClassName={this.getLocationClassName}
            onLocationMouseOver={this.handleLocationMouseOver}
            onLocationMouseOut={this.handleLocationMouseOut}
            onLocationMouseMove={this.handleLocationMouseMove}
            onLocationClick={this.handleLocationClick}
            onLocationFocus={this.handleLocationFocus}
            onLocationBlur={this.handleLocationBlur}
          />

          <div className="map_tooltip" style={this.state.tooltipStyle}>
            {this.state.pointedLocation}
          </div>

          {
          	this.state.currentLink &&
          	<AniLink id="linknav" fade to={this.state.currentLink} style={{display: 'none'}} duration={0.4}></AniLink >
          }
        </div>
      )
    }
  }


  export default InteractiveWorldMap
